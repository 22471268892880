.logout {
    margin: 0 1em .5em 1em;
    padding: 0 .5em;
    display: none;

    // @media (min-width: 1366px) {
        display: block;
    // }

    &Container {
        display: flex;
        flex: 1;
        gap: .5em;

        padding: .5em .5em;

        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
    }

    &Container:hover {
        background-color: var(--chat-color);
        border-radius: 0.5em;
        cursor: pointer;
        color: #fff;
    }
}