// chat.module.scss

.chatComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;
    width: 100%;
    max-width: 850px;

    height: -webkit-fill-available;
    height: -moz-available;

    background-color: var(--neutral-01);
    border-radius: 0.5em;
}

.promptContainer {
    display: flex;
    width: -webkit-fill-available;
    padding: 1em 0;
}

.promptComponent {
    &Container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
    }

    &Component {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;

        height: auto;
        width: 100%;
        max-width: 850px;

        border-radius: 8px;

        &Input {
            display: flex;
            flex: 1;
            position: static;
        }

        &Uploadfile {
            background-color: #fff;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &InputTextareaContainer {
            display: flex;
            flex-grow: 1;
            background-color: #fff;
            padding: 1em 1em 1em 0.5em;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
        }

        &InputTextarea {
            width: 100%;
            height: 20px;
            max-height: 5.8em;

            color: var(--mate-black);
            font-size: 15px;

            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            resize: none;

            background-color: #fff;
            overflow-y: auto;
        }

        @media (max-width: 400px) {
            &InputTextarea {
                font-size: 12px;

                &::placeholder {
                    font-size: 12px;
                }
            }
        }

        &InputTextarea:focus {
            outline: 0;
        }

        &UploadButton {
            border-top-left-radius: 8px;
            margin: 0px;
            padding-top: 1em;
            padding-left: .5em;
            border: none;
            background-color: #fff;
            color: var(--neutral-08);
        }

        &SendButton,
        &CloseButton {
            margin: 0px;
            padding: 0px 1em;
            border: none;
            background: var(--chat-color);
            color: #fff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &Icon {
            transform: scale(.75); // 18 px
        }

    }
}