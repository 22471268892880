.prompt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: auto;
    width: 100%;
    max-width: 850px;

    border-radius: 8px;

    @media (max-height: 480px) {
        margin: 0.25em auto 0;
        border-radius: 4px;
    }

    &Input {
        display: flex;
        flex: 1;
        position: static;

        @media (max-height: 480px) {
            min-height: 40px;
            max-height: 80px;
        }
    }

    &Uploadfile {
        background-color: #fff;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        @media (max-height: 480px) {
            padding: 0.5em;
        }
    }

    &InputTextareaContainer {
        display: flex;
        flex-grow: 1;
        background-color: #fff;
        padding: 1em 1em 1em 0.5em;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
    }

    &InputTextarea {
        width: 100%;
        height: 20px;
        max-height: 5.8em;

        color: var(--mate-black);
        font-size: 15px;

        border: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        resize: none;

        background-color: #fff;
        overflow-y: auto;

        @media (max-height: 480px) {
            min-height: 20px;
            max-height: 60px;
            font-size: 14px;
            line-height: 1.4;
        }
    }

    &InputTextarea:focus {
        outline: 0;
    }

    &UploadButton {
        border-top-left-radius: 8px;
        margin: 0px;
        padding-top: 1em;
        padding-left: .5em;
        border: none;
        background-color: #fff;
        color: var(--neutral-08);
    }

    &SendButton {
        margin: 0px;
        padding: 0px 1em;
        border: none;
        background: var(--chat-color);
        color: #fff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &Icon {
        transform: scale(.75); // 18 px
    }

}