.newChatComponent {
    display: flex;
    flex-direction: row;
    gap: .5em;

    margin: 0 1.5em 1em 1.2em;
    padding: .5em .5em .2em;

    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}

.newChatComponent:hover {
    background-color: var(--chat-color);
    border-radius: 0.5em;
    cursor: pointer;
    color: #fff;
}