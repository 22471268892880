// App.module.scss

.login {
    display: flex;
    flex-direction: column;

    height: 100vh;
    
    background: linear-gradient(-35deg, var(--principal-purple) 50%, var(--principal-green));

    &Header {
        display: flex;
        justify-content: flex-end;

        height: 100px;

        padding: 1em 2em;

        &Button {
            display: flex;
            height: max-content;
            line-height: 14px;

            padding: .8em 1.2em;

            color: #fff;
            border: solid 1px #fff;
            border-radius: .5em;

            cursor: pointer;
        }
    }

    &Logos {
        display: flex;
        flex: 1;
        margin-bottom: 132px;
        
        align-items: center;
        justify-content: space-evenly;

        &Seat {
            display: block;
            height: 200px;
        }

        &Vortex {
            display: block;
            height: 100px;
        }
    }
}