.conversations {
    display: flex;
    flex: 1;
    flex-direction: column;
    
    margin-bottom: 1em;
    scrollbar-width: thin;

    padding: 0 2em 1em 2em;

    @media (min-width: 1366px) {
        display: flex;
        width: var(--width--history-4em);
        flex-direction: column;
        height: calc(100vh - 385px);
    }

    /* scroll */
    overflow: auto;
    height: fit-content;

    &IsActive {
        display: block;
        width: var(--width--history - 4em);

        @media (min-width: 1366px) {
            display: none;
        }
    }

    &Title {
        width: inherit;
        height: 24px;

        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 24px;

        /* identical to box height, or 126% */
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.5px;

        color: var(--mate-black);


        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &SubtitleGroup {
        display: flex;
        flex-direction: row;
        gap: .5em;
        align-items: flex-end;
    }

    &Subtitle {
        width: inherit;
        height: 17px;
        margin: 1em 0 .5em 0;

        font-weight: 300;
        font-size: 15px;
        line-height: 17px;

        /* identical to box height, or 126% */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        letter-spacing: -0.5px;

        color: var(--mate-black);

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &SubtitleHour {
        width: inherit;
        height: 17px;
        margin: 1em 0 .5em 0;

        font-weight: 300;
        font-size: 10px;
        line-height: 17px;

        /* identical to box height, or 126% */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        letter-spacing: -0.5px;

        color: var(--mate-black);

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &QuestionContainer{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        width: inherit;
        height: 17px;
        margin: 0.3em 0 0 0.3em;
        padding: .2em .5em;
    }

    &Question {

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;

        /* identical to box height, or 126% */
        align-items: center;
        justify-content: flex-start;
        letter-spacing: -0.5px;

        /* Inside auto layout */
        order: 0;
        flex-grow: 0;

        /* Truncate text */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    &Question:hover {
        background-color: var(--chat-color);
        border-radius: .5em;
        cursor: pointer;

        color: #fff;
    }

    &TrashIcon {
        margin-top: auto;
    }
}



