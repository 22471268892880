// Estilos para el contenedor
.root {

    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    height: 100vh;
    padding-top: 0px;

    @media (min-width: 1365px) {
        display: block;
        height: calc(100vh - 44px);
        padding: 0px 122px 43px 122px;
        background: linear-gradient(-35deg, var(--principal-purple) 15%, var(--principal-green));
    }
}


.menu {

    width: 100%;

    display: grid;
    grid-template-columns: auto 1fr;

    height: calc(100vh - 100px);

    // Estilos para la columna chat
    .chat {
        display: flex;
        justify-content: center;
        padding: 1em;
        background-color: var(--neutral-01);
    }
}