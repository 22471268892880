.dialogFeedback {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: .5em;

    margin-top: -1em;

    color: var(--neutral-07);
}

.dialogFeedback__thumbup:hover,
.dialogFeedback__thumbdown:hover {
    cursor: pointer;
    color: var(--chat-color);
}