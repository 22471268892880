// menu.module.scss

.menu {
    z-index: 10;

    &Options {
        width: 300px;

        @media (max-width: 480px) {
            width: 100vw;
            max-width: 100%;
        }

        display: grid;
        display: grid;

        grid-template-rows: auto auto 1fr auto auto;
        overflow-y: hidden;
        background-color: var(--neutral-02);

    }

    &IsActive {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        width: var(--width--menu);
    }

    &NotActive {
        display: grid;
        grid-template-rows: auto;
        height: 100%;
        background-color: var(--neutral-02);
    }

    &Icon {
        display: grid;
        align-items: center;
        justify-content: center;

        height: 60px;
        width: 48px;

        cursor: pointer;

        &Menu {
            display: block;
        }

        &Close {
            display: none;
        }

        .historyIsActive &Menu {
            display: none;
        }

        .historyIsActive &Close {
            display: block;
        }
    }

}