.dropdown {
    position: relative;
    width: 267px;
    border-radius: 4px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    background-color: var(--neutral-02);

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
}

.dropdownHeader {
    color: var(--neutral-08);
    font-weight: 600;

    padding: .2em .2em .2em 1em ;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdownBody {
    position: absolute;
    margin-top: .2em;

    top: 100%;
    left: 0;
    width: calc(100% - 12px);
    background-color: var(--neutral-02);
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1;

    padding: 5px;
    border-top: 1px solid #E5E8EC;
    display: none;
}

.dropdownBody.open {
    display: block;
}

.dropdownItem {
    display: flex;
    padding: .2em;
    color: var(--neutral-08);
    gap: 10px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdownItem:hover {
    cursor: pointer;
}

.dropdownItemDot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.dropdownItemDot.selected {
    opacity: 1;
}

.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}