.popUp {

    position: absolute;
    top: calc(-150px - 2em);
    right: -2em;
    z-index: 10;
    border-radius: 8px;
    padding: 1em;

    background-color: #fff;
    border: solid 1px var(--neutral-02);
    color: var(--mate-black);
}

.form {
    display: flex;
    flex-direction: column;
    gap: .5em;
    width: 200px;
    margin: auto;

    font-size: 15px;

    &CloseIcon {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        height: 14px;
        color: var(--mate-black);

        &icon {
            font-size: 12px;
            height: 12px;
        }
    }
               

    &Tokens,
    &Temperature {
        font-size: 11px;
        font-weight: 700;
    }

    &TemperatureLine {
        display: flex;
        flex-direction: row;
        gap: .8em;
    }

    &TemperatureValue {
        margin-top: 1px;
        font-size: 14px;
        font-weight: 500;
        color: var(--mate-black);
    }

    &Tokens, label,
    &Temperature label {
        display: block;
        margin-bottom: 5px;
    }

    &Temperature label {
        display: block;
        margin-top: 4px;
    }

    &Button {
        align-self: flex-end;
        margin-top: 1em;
        padding: 10px 20px;
        background-color: var(--chat-color);
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    // &Button:hover{
    //     font-weight: 700;
    // }
}

input[type="number"] {
    padding: 8px;
    border: 1px solid var(--neutral-02);
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

input[type="range"] {
    margin-top: 0px
}

input[type=range] {
    border: none;
    padding-top: .5em;
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid var(--neutral-02);
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 2pxvar(--neutral-02);
    border: 2px solid var(--chat-color);
    height: 20px;
    width: 20px;
    border-radius: 14px;
    background: var(--chat-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #FFFFFF;
}

