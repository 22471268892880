.componentContainer {
    display: flex;
    flex-direction: row;
    background-color: red;

    height: -webkit-fill-available;
    height: -moz-available;
}

.textoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    height: -webkit-fill-available;
    height: -moz-available;

    background-color: var(--neutral-01);
    border-radius: .5em;
}