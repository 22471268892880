/* header.module.scss */

.header {
    display: flex;
    flex-direction: row;
    gap: 1em;
    height: 80px;


    align-items: center;
    justify-content: space-between;

    /* Change position of header in screen */
    @media (min-width: 1365px) {
        width: 100%;
        background: none;
    }

    @media (max-width: 1365px) {
        width: 100%;
        background: var(--neutral-02)
    }


    &LogoBlocks {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        gap: 1em;

        &Seat {
            display: flex;
            height: 80px;
            flex: 1;
            align-items: center;

            @media (max-width: 480px) {
                height: 60px;
            }
        }

        &Vortex {
            display: flex;
            flex: 1;
            justify-content: center;

            @media (max-width: 480px) {
                height: 60px;
                align-items: center;
            }

            @media (max-width: 360px) {
                display: none;
            }
        }

        &Context {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: flex-end;
            gap: .5em;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }


    &Title {

        display: flex;
        gap: 1em;

        visibility: hidden;
        width: 0px;
        align-items: center;
        margin-bottom: 0.7em;

        @media (min-width: 1365px) {
            visibility: visible;
            width: auto;
        }

        &Context {
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.5px;
            text-align: center;

            /* Neutral/neutral00 */
            color: #FFFFFF;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        &Description {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            // line-height: 28px;
            letter-spacing: -0.5px;

            /* Neutral/neutral00 */
            color: #FFFFFF;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }

    }

    &LogoSeat {
        padding: 1em;

        height: inherit;
        pointer-events: none;

        @media (max-width: 1364px) {
            filter: brightness(0);
        }
    }

    &LogoVortex {
        height: 42px;
        pointer-events: none;

        @media (max-width: 1365px) {
            color: black;
        }
    }

    &LogoConfidential {
        height: 50px;
        pointer-events: none;
    }

    &UserInfo {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-right: 36px;
        margin-top: 1em;
        gap: 1em;
        color: #fff;

        @media (min-width: 1365px) {
            margin-right: 12px;
        }

        &Photo {
            height: 36px;
            border-radius: 50%;
        }

    }
}