.welcomeComponent {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 1em;

    &Logo {
        display: block;
        height: 100px;
    }

    &Title {
        height: 28px;

        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        /* identical to box height, or 117% */
        letter-spacing: -0.5px;

        color: var(--matte-black);
    }
}