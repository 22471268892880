

.fileContextComponent {
    display: flex;
    align-items: center;
    gap: .2em;
    margin-top: 1em;
    height: 24px;
    padding: .2em 0 0 0.2em;

    width: 100%;
    max-width: 850px;
    background-color: var(--neutal-03);

    font-size: 12px;

    &Icon {
        transform: scale(.65); 
        padding-top: 6px;
        color: red;

    }
}



