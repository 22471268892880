// chat.module.scss

.chatComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;
    width: 100%;
    max-width: 850px;

    height: -webkit-fill-available;
    height: -moz-available;

    background-color: var(--neutral-01);
    border-radius: 0.5em;
}

.promptContainer {
    display: flex;
    width: -webkit-fill-available;
    padding: 1em 0;
}

