.disclaimerComponent {
    width: 100%;
    max-width: 850px;

    padding-top: 1em;
    padding-bottom: 1em;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 117% */
    letter-spacing: -0.5px;

    @media (max-height: 480px) {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 10px;
        line-height: 12px;
        margin: 0 auto;
    }
}

.versionComponent {
    width: 100%;
    max-width: 850px;
    text-align: end;

    padding-bottom: 1em;

    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height, or 117% */
    letter-spacing: -0.5px;

    @media (max-height: 480px) {
        padding-bottom: 0.5em;
        font-size: 9px;
        line-height: 12px;
    }
}