.dialogComponent {
    display: flex;
    flex: 1;
    flex-grow: 1;
    // height: calc(100vh - 201px);
    width: 100%;
    max-width: 850px;
    overflow-y: auto;
    margin-top: 1em;
    scrollbar-width: thin;

    @media (max-width: 768px) {
        margin-top: 0.5em;
    }
}



.dialogContainer {
    height: 500px;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 850px;
    max-height: 51vh;
    flex: 1;
    margin: 1em 0;
    padding: 1em 1em 1em 0;
    gap: 1.5em;

    @media (max-width: 768px) {
        padding: 0.5em;
        gap: 1em;
    }

    &AskComponent,
    &AnswerComponent {
        display: flex;
        flex-direction: row;
        gap: 2em;

        @media (max-width: 480px) {
            gap: 0.75em;
        }
    }

    &AskComponent {
        &Text {
            width: 100%;
            padding: .2em 1.2em;
            border-radius: .5em;

            color: var(--matte-black);
            background-color: #fff;

            @media (max-width: 480px) {
                padding: 0.5em 0.75em;
            }
        }

    }

    &AnswerComponent {
        &Text {
            display: flex;
            gap: 0.5em;
            flex-direction: row;
            align-items: center;

            width: 100%;
            padding: .2em 1.2em;
            border-radius: .5em;

            color: #fff;
            background-color: var(--chat-color);

            @media (max-width: 480px) {
                padding: 0.5em 0.75em;
            }
        }

    }

    &ChatAvatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        min-width: 48px;
        width: 48px;
        height: 48px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 480px) {
            min-width: 36px;
            width: 36px;
            height: 36px;
        }
    }

    &Photo {
        border-radius: 50%;

        @media (max-width: 480px) {
            width: 36px;
            height: 36px;
        }
    }
}

.markdownTable table {
    border-collapse: collapse;
    width: 100%;
}

.markdownTable th,
.markdownTable td {
    border: 1px solid #fff;
    padding: 8px;
    text-align: left;

    @media (max-width: 480px) {
        padding: 0.25em;
        font-size: 0.875em;
    }
}

.loader {
    width: 18px;
    height: 18px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}