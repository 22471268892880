:root {
  /* Main Colors */
  --principal-green: #1effb9;
  --principal-purple: #5419f7;

  --chat-color: #4991D6;

  --mate-black: #2c2a29;

  /* Neutral colors */
  --neutral-00: #ffffff;
  --neutral-01: #f4f4f4;
  --neutral-02: #eaeaea;
  --neutral-03: #d9d9d9;
  --neutral-04: #bfbfbf;
  --neutral-05: #a6a6a6;
  --neutral-06: #8c8c8c;
  --neutral-07: #666666;
  --neutral-08: #4d4d4d;
  --neutral-09: #333333;
  --neutral-10: #000000;

  /* Measures */
  --width--history: 354px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
